<template>
  <div>
    <!-- Separator with angled path -->
    <TailSeparator />
    <nav class="px-4 lg:px-6 py-4">
      <div class="flex justify-end items-center mx-auto">
        <!-- Navigation Links -->
        <div class="flex items-center space-x-8 font-proxima font-normal text-center">
          <a href="/polityka-prywatnosci" v-html="footerCont.policy"></a>
          <a href="/regulamin" v-html="footerCont.terms"></a>
          <p v-html="footerCont.year"></p>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import TailSeparator from './TailSeparator.vue'
import { loadContent } from '../utils/contentLoader';

export default {
  name: 'SectionFooter',
  components: {
    TailSeparator
  },
  data() {
    return {
      footerCont: {
        "policy": "Polityka prywatności",
        "terms": "Regulamin",
        "year": "2024"
      }
    }
  },
  async created() {
    const result = await loadContent('/content/footer.json', 'Error loading footer content');
    if (result) {
      this.footerCont = result;
    }
  }
}
</script>
