<template>
  <a :href="buttonCont.href" target="_blank" rel="noopener noreferrer"
    class="font-proxima font-bold inline-block text-black bg-coral-orange rounded-full" :class="[
      small ? 'px-4 py-2' : [
        'px-5 sm:px-6 md:px-10 xl:px-14 ',
        'py-2 sm:py-3 md:py-5 xl:py-7  ',
        'text-base sm:text-xl md:text-2xl xl:text-5xl '
      ]
    ]" v-html="buttonCont.join">
  </a>
</template>

<script>
import { loadContent } from '../utils/contentLoader';

export default {
  name: 'JoinButton',
  props: {
    small: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      buttonCont: {
        "join": "DOŁĄCZ",
        "href": "https://docs.google.com/forms/d/e/1FAIpQLSfALUTM1GGYiIpik1emYyAZwZu437kxhZHldDyLhNbUGrF9MA/viewform"
      }
    };
  },
  async created() {
    const result = await loadContent('/content/joinbutton.json', 'Error loading button content');
    if (result) {
      this.buttonCont = result;
    }
  }
}
</script>
