<template>
  <div id="app" class="overflow-x-hidden">
    <div ref="scrollContainer" class="bg-light-gray parallax-container overflow-x-hidden"
      :style="{ backgroundPosition: backgroundPosition }" @scroll="handleScroll">
      <div class="flex flex-col justify-between min-h-full">
        <SectionHeader />
        <div class="flex-grow flex flex-col justify-between">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <router-view />
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <SectionCookies />
        </div>
        <SectionFooter />
      </div>
    </div>
  </div>
</template>

<script>
import SectionHeader from './components/SectionHeader.vue';
import SectionFooter from './components/SectionFooter.vue';
import SectionCookies from './components/SectionCookies.vue';

export default {
  components: {
    SectionHeader,
    SectionFooter,
    SectionCookies,
  },
  data() {
    return {
      scrollY: 0,
    };
  },
  methods: {
    handleScroll() {
      this.scrollY = this.$refs.scrollContainer.scrollTop;
    },
  },
  computed: {
    backgroundPosition() {
      // Adjust the scroll factor and loop with modulus
      return `center ${this.scrollY * 0.5 % 210000}px`;
    },
  },
};
</script>

<style>
html {
  scroll-behavior: smooth;
  /* Smooth scrolling for anchor links */
}

.parallax-container {
  /* Parallax background settings */
  background-image: url('~@/assets/background-parallax.webp');
  background-repeat: repeat-y;
  background-size: 100% auto;
  height: 100vh;
  overflow-y: scroll;
  position: relative;
  scroll-behavior: smooth;
  /* Enable smooth scrolling within this container */
}
</style>
