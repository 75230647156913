<template>
  <div v-if="!cookieInteracted" class="fixed xl:sticky bottom-0 left-0 w-full bg-sunshine-yellow p-4 z-50">
    <div
      class="font-proxima text-xs sm:text-sm space-y-4 sm:flex sm:space-x-4 sm:space-y-0 items-center justify-center">
      <p class="font-normal text-center">
        <span v-html="cookiesCont.text1"></span>
        <a class="font-medium underline" href="/polityka-prywatnosci" v-html="cookiesCont.href"></a>
        <span v-html="cookiesCont.text2"></span>
      </p>
      <div class="flex items-center justify-start space-x-4">
        <p class="text-center">
          <button @click="rejectCookies" class="rounded-full border-black border px-3 py-2"
            v-html="cookiesCont.reject"></button>
        </p>
        <p class="font-semibold text-center">
          <button @click="acceptCookies" class="rounded-full border-black border-2 px-6 py-2 bg-light-gray"
            v-html="cookiesCont.accept"></button>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { loadContent } from '../utils/contentLoader';

const cookiesCont = ref({
  "text1": "### #### ### ",
  "href": "###",
  "text2": "#",
  "accept": "###",
  "reject": "###"
})
const cookieInteracted = ref(false)

const initializeAnalytics = () => {
  // Load the GA script
  const script = document.createElement('script')
  script.async = true
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-QVWT0VK5M3'
  document.head.appendChild(script)

  // Initialize dataLayer
  window.dataLayer = window.dataLayer || []
  function gtag() {
    window.dataLayer.push(arguments)
  }
  gtag('js', new Date())
  gtag('config', 'G-QVWT0VK5M3')
}

const acceptCookies = () => {
  document.cookie = "SectionCookies=true; max-age=" + 60 * 60 * 24 * 365 + "; path=/"
  cookieInteracted.value = true
  initializeAnalytics()
}

const rejectCookies = () => {
  document.cookie = "SectionCookies=false; max-age=" + 60 * 60 * 8 + "; path=/"
  cookieInteracted.value = true
}

onMounted(() => {
  const cookieValue = document.cookie.split(';').find((item) => item.trim().startsWith('SectionCookies='));

  if (cookieValue) {
    cookieInteracted.value = true

    if (cookieValue.split('=')[1].trim() === 'true') {
      initializeAnalytics()
    }
  }
})

onMounted(async () => {
  const cont = await loadContent('/content/cookies.json', 'Error loading cookies content');
  if (cont) {
    cookiesCont.value = cont;
  }
})
</script>
